<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item v-for="item in menu" :key="item.text" :to="item.link" link>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dense dark>
      <v-app-bar-nav-icon @clFick.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!--<v-icon
        class="mx-4"
        large
      >
        mdi-school
      </v-icon>-->
      <img class="mr-3" :src="require('@/assets/logo.svg')" height="47"/>
      <!--<v-img src="@/assets/logo.svg" height="36"> 
            </v-img>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">EstudioApp</span>
      </v-toolbar-title>-->
      <!--<v-spacer></v-spacer>
      <v-row
        align="center"
        style="max-width: 650px"
      >
        <v-text-field
          :append-icon-cb="() => {}"
          placeholder="Search..."
          single-line
          append-icon="mdi-magnify"
          color="white"
          hide-details
        ></v-text-field>
      </v-row>-->
      <v-spacer></v-spacer>
      <v-btn icon large>
        <v-avatar size="32px" item>
          <v-img
              v-bind:src="this.$store.getters.usuario.avatar"
              alt="this.$store.getters.usuario.name"
          ></v-img>
        </v-avatar>
      </v-btn>
      <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                    v-bind:src="this.$store.getters.usuario.avatar"
                    v-bind:alt="this.$store.getters.usuario.name"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                    this.$store.getters.usuario.name
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                    this.$store.getters.usuario.email
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="logout">Salir</v-btn>
            <!--<v-btn color="primary" text @click="menu = false">Save</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <!--<v-row
          justify="center"
          align="center"
        >
          <v-col class="shrink">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  :href="source"
                  icon
                  large
                  target="_blank"
                  v-on="on"
                >
                  <v-icon large>mdi-code-tags</v-icon>
                </v-btn>
              </template>
              <span>Source</span>
            </v-tooltip>
          </v-col>
        </v-row>-->
                                <router-view/>
                                  <v-snackbar
                                    v-model="snackbar"
                                     :color="colorSnackbar"
                                     :timeout="timeout"
                                    >
                                   {{textoSnackbar}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>
      </v-container>
    </v-main>
    <v-footer color="primary" app>
      <span class="white--text">
        <a href="https://www.nive.la" target="blank" class="white--text"
        >Nivel A</a
        >
        &copy; {{ new Date().getFullYear() }}
      </span>
      <v-spacer></v-spacer>
          <v-btn
     class="mx-2"
      fab
      dark
      color="orange"
      @click="dialog = true"
    >
      <v-icon dark>
        mdi-comment-question-outline
      </v-icon>
    </v-btn>
    </v-footer>
    
 <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
         Contacto de soporte técnico
        </v-card-title>
        
    <v-card-text>
          <v-form>

            <v-textarea
              v-model="mensaje"
              :rules="mensajeRules"
              outlined
              required
              label="Cuéntanos que es lo que pasa"
              value=""
            ></v-textarea>
            <br>
            
          <v-row>
            <v-col :cols="6">
              <v-select
                item-text="name"
                item-value="id"
                :items="items"
                label="Priodidad"
                dense
                v-model="ticketPriority_id"
              ></v-select>
            </v-col>
            <v-col :cols="6">

        <v-file-input
            class="espacio_mt12"
              prepend-icon="mdi-camera"
              show-size
              truncate-length="16"
              accept="image/*"
              label="Evidencia"
              small-chips
              v-model="imagen"
            ></v-file-input>    


            </v-col>
          </v-row>


           </v-form>
    </v-card-text>
    <v-card-actions>

      <v-spacer></v-spacer>
        <v-btn
        :disabled="!isBtnValid"
        color="green darken-1"
        text
        @click="sendTicket()"
      >
        Enviar 
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="cancelarEnvioTicket()"
      >
        Volver
      </v-btn>
    </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>


</template>

<script>
//import HelloWorld from './components/HelloWorld';
//import Home from './views/Home';

export default {
  name: "App",
  components: {
    //Home,
  },
  props: {
    source: String,
  },
  data: () => ({
            isBtnValid: true,
            ticketPriority_id:{
              name: "Normal",
              id: 3
            },
            items: [
              {
                  name: "Crítica",
                  id: 1
                },
                {
                  name: "Alta",
                  id: 2
                },
                {
                  name: "Normal",
                  id: 3
                },
                {
                  name: "Baja",
                  id:4
                }
            ],
            imagen : [],
            timeout:2000,
            snackbar:false,
            textoSnackbar:"",
            colorSnackbar:"",
            dialog: false,
            mensaje: '',
            mensajeRules: [
              v => !!v || 'Campo requerido',
            ],
    drawer: null,
    /*menu: [*/
    /*{ icon: "mdi-account-circle-outline", text: "Home", link: "/" },*/
    /*{ icon: "mdi-history", text: "About", link: "/about" },
        { icon: "mdi-history", text: "Dashboard", link: "/dashboard" },*/
    /*{ icon: "mdi-school", text: "Cursos Prototipos", link: "/cursos" },
      {
        icon: "mdi-school",
        text: "Cursos Multiplicados",
        link: "/cursos-multiplicados",
      },*/
    /*],*/
    //menu: [],
  }),
  computed: {
    menu: {
      get() {
        return this.$store.getters.usuario.menu;
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch("fetchUsuario");
  },
  created() {
    this.$vuetify.theme.dark = false;
    /*this.$store.dispatch("fetchUsuario").then(() => {
      console.log("Got some data, now lets show something in this component ");
      this.menu = this.$store.getters.usuario.menu;
    });*/
    //this.menu = this.$store.getters.usuario.menu;
  },
  mounted() {
    //this.$store.dispatch('fetchUsuario')
  },
  methods: {
    normalSnackbar(color, text){
                    this.colorSnackbar  = color;
                    this.textoSnackbar  = text;
                    this.snackbar       = true;
    },
    cancelarEnvioTicket(){
      this.isBtnValid=true;
      this.dialog   =false;
    }
    ,async sendTicket(){   
    this.isBtnValid=false;
      if(this.mensaje == '' || this.mensaje == ' ' || this.mensaje == null ){
         this.normalSnackbar("lime darken-2", "El campo mensaje no puede estar vacío");
         return false;
      }

      let formData = new FormData();
          formData.append("imagen", this.imagen);
          formData.append("ticketPriority_id", this.ticketPriority_id);
          formData.append("mensaje", this.mensaje);
          formData.append("hostname", window.location.hostname);
          formData.append("href", window.location.href);
          formData.append("pathname", window.location.pathname);

      const url = `/api/save-ticket`;
      const response = await this.$api.post(url, formData, 
                      { 
                        headers: {'content-type': 'multipart/form-data'} 
                      }
                    );
          
            let { message
                , color
                ,success
                } = response.data;
            if(success){
                          this.normalSnackbar(color, message);
                          this.mensaje='';
                          this.imagen = [];
                          this.isBtnValid=true;
            }
              this.dialog=false;
              
    },

    logout() {
      this.$api.post("/logout").then(() => {
        //this.$router.push({ name: 'Home'})
        window.location =
            "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
            process.env.VUE_APP_API_URL +
            "/redirect";
      });
    },
  },
};
</script>

<style scoped>

.espacio_mt12{
  padding-top: 0 !important;
  margin-top: 0 !important;
  }

</style>